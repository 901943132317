import { get, post } from './http'

//注册
//account 手机号
//password 密码
//inviteCode 邀请码
//code 验证码
export function register(account,password,inviteCode,code){
    const data = {
        sid:"u01",
        account: account,
        password: password,
        inviteCode: inviteCode,
        code: code
      }
    return post('/v1/member/signin',data);
}

//获取验证码
//kind 类型 1: 注册 2:忘记密码 4: 验证码登录.
//receiver 手机号码
export function getCode(kind,receiver){
  const param = {
    sid: "u06",
    kind: kind,
    receiver: receiver
  }
  return get('/v1/member/captcha',param);
}
