<template>
  <div class="starfield">
    <div class="static"></div>
    <div class="moving-1"></div>
    <div class="moving-2"></div>
    <div class="moving-3"></div>
    <div class="regist_card">
      <div class="title">
        <h1>注册新用户</h1>
      </div>
      <el-input
        placeholder="请输入手机号"
        v-model="input"
        clearable
        type="number"
      >
      </el-input>
      <div style="margin-top: .9375rem">
        <el-input placeholder="请输入验证码" v-model="input2">
          <template #append
            ><el-button type="primary" @click="getcode">{{
              buttonText
            }}</el-button></template
          >
        </el-input>
      </div>
      <div style="margin-top: .9375rem">
        <el-input
          placeholder="请输入密码"
          v-model="input3"
          show-password
        ></el-input>
      </div>
      <div style="margin-top: .9375rem">
        <el-input v-model="input4" placeholder="请输入邀请码">
          <template #prepend>邀请码：</template>
        </el-input>
      </div>
      <div style="margin-top: 1.9375rem">
        <el-button type="primary" @click="submit">立即注册</el-button>
      </div>
      <div class="foot" style="margin-top: 1.9375rem">
        荣耀云
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import router from "../../router/index";
import { register, getCode } from "../../util/http/api";
export default {
  data() {
    return {
      invite: this.$route.query.invite,
      input: ref(""),
      input2: ref(""),
      input3: ref(""),
      input4: ref(this.$route.query.invite),
      sendTime: 0,
      timer: "",
      buttonText: "获取验证码",
    };
  },
  methods: {
    getcode() {
      if (
        localStorage.getItem("sendTime") == null ||
        localStorage.getItem("sendTime") <= 0
      ) {
        localStorage.setItem("sendTime", 60);
        getCode(1, this.input).then((res) => {
          console.table(res);
          if(res.errCode==200){
            alert("发送成功");
          }else{
            alert(res.errMsg);
          }
        });
        this.timer = setInterval(this.countDown, 1000);
      }
    },
    submit() {
      register(this.input, this.input3, this.input4, this.input2).then(
        (res) => {
          console.table(res);
          if (res.errCode == 200) {
            alert("注册成功");
            router.push("/download");
          } else {
            alert(res.errMsg);
          }
        }
      );
    },
    countDown() {
      localStorage.setItem("sendTime", localStorage.getItem("sendTime") - 1);
      this.buttonText = localStorage.getItem("sendTime") + "";
      if (localStorage.getItem("sendTime") <= 0) {
        this.buttonText = "获取验证码";
        clearTimeout(this.timer);
      }
    },
  },
  created() {
    if (
      localStorage.getItem("sendTime") == null ||
      localStorage.getItem("sendTime") <= 0
    ) {
      this.buttonText = "获取验证码";
      clearTimeout(this.timer);
    } else {
      this.buttonText = localStorage.getItem("sendTime")+"";
      this.timer = setInterval(this.countDown, 1000);
    }
  },
};
</script>

<style>
@import url("../../css/style.css");
.title {
  height: 6.25rem;
  text-align: center;
  padding-top: 1.25rem;
  margin: 0 auto;
}
.foot {
  font-size: 0.5rem;
  color: #d5d5d5;
}
div.regist_card {
  width: 18.75rem;
  height: 31.25rem;
  background-color: #f1f1f6;
  margin: 0 auto;
  margin-top: 9.375rem;
  border-radius: 0.25rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
</style>
